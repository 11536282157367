<template>
  <div class="details">
    <Header></Header>
    <div class="details-content">
      <div class="content-top">
        <span>{{ itemObj.info.title }}</span>
        <div>
          <span
            >{{ itemObj.info.createtime }} 来源: {{ itemObj.info.source }}</span
          >
        </div>
      </div>
      <!-- <div class="content-txt"> -->
      <video
        width="370px"
        :src="BaseUrl + itemObj.info.attrfile"
        controls
      ></video>
      <div v-html="itemObj.info.content" class="content-txt"></div>

      <div class="content-about">
        <div>
          <span>相关资讯</span>
        </div>
        <div class="about-item">
          <div
            class="item-list"
            v-for="item in itemObj.xiangguangList"
            :key="item.id"
            @click="goHotDetails(item.id)"
          >
            <span>{{ item.title }}</span>
            <span>{{ item.createtime }}</span>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../../../components/mobile/Header.vue";
import Footer from "../../../../components/mobile/Footer.vue";
export default {
  name: "HotDetails",
  data() {
    return {
      id: "",
      itemObj: {},
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.id = this.$route.params.id;
    this.getDetails();
  },
  methods: {
    getDetails(id) {
      this.$http({
        url: "/yingshi/detail",
        params: {
          id: id ? id : this.id,
        },
      }).then((res) => {
        console.log(res, "details");
        this.itemObj = res.data;
      });
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="less" scoped>
.details {
  .details-content {
    width: 690px;
    margin: 40px auto;
    .content-top {
      width: 690px;
      padding-bottom: 19px;
      margin-bottom: 34px;
      border-bottom: 1px solid #bcbcbc;
      > span:nth-child(1) {
        font-size: 28px;
        color: #333333;
      }
      > div:nth-child(2) {
        display: block;
        margin-top: 19px;
        font-size: 20px;
        color: #999999;
      }
    }
    .content-txt {
      margin-bottom: 50px;
      font-size: 24px;
      color: #666666;
      /deep/ img {
        width: 690px;
        height: 500px;
      }
      /deep/video {
        width: 690px;
        height: 500px;
      }
    }
    .content-about {
      margin-bottom: 60px;
      > div:nth-child(1) {
        width: 120px;
        padding-bottom: 14px;
        margin-bottom: 14px;
        font-size: 28px;
        color: #12407d;
        border-bottom: 1px solid #12407d;
      }
      .about-item {
        width: 690px;
        .item-list {
          display: flex;
          justify-content: space-between;
          width: 690px;
          margin-bottom: 9px;
          font-size: 20px;
          color: #666666;
          > span:nth-child(1)::before {
            display: inline-block;
            content: "";
            width: 4px;
            height: 4px;
            background-color: #12407d;
            margin-right: 10px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
